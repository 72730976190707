import React, { useState } from 'react';
import { Search, Star, X, ChevronDown, ChevronUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const FilterSection = ({ title, children, isOpen, toggleOpen }) => {
  return (
    <div className="mb-4 bg-white rounded-lg shadow-sm overflow-hidden">
      <button
        className="w-full flex justify-between items-center p-4 bg-white text-teal-700 hover:bg-teal-50 transition-all duration-300 ease-in-out"
        onClick={toggleOpen}
      >
        <span className="font-semibold">{title}</span>
        <motion.div
          initial={false}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <ChevronDown size={20} />
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="p-4 bg-gradient-to-b from-teal-50 to-white">
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const CategoryItem = ({ category, selectedCategories, selectedSubcategories, handleCategoryChange, handleSubcategoryChange }) => {
  const [isSubcategoriesOpen, setIsSubcategoriesOpen] = useState(false);

  return (
    <div className="mb-2">
      <div className="flex items-center">
        <input
          type="checkbox"
          id={`category-${category.id}`}
          checked={selectedCategories.includes(category.id)}
          onChange={() => handleCategoryChange(category.id)}
          className="mr-2 accent-teal-500 h-4 w-4"
        />
        <label 
          htmlFor={`category-${category.id}`} 
          className="text-gray-700 hover:text-teal-600 cursor-pointer transition-colors duration-200 flex-grow"
        >
          {category.name}
        </label>
        {category.subcategories && category.subcategories.length > 0 && (
          <button
            onClick={() => setIsSubcategoriesOpen(!isSubcategoriesOpen)}
            className="text-teal-500 hover:text-teal-700 transition-colors duration-200 p-1 rounded-full hover:bg-teal-100"
          >
            <motion.div
              initial={false}
              animate={{ rotate: isSubcategoriesOpen ? 180 : 0 }}
              transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <ChevronDown size={16} />
            </motion.div>
          </button>
        )}
      </div>
      {category.subcategories && category.subcategories.length > 0 && (
        <AnimatePresence initial={false}>
          {isSubcategoriesOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
              className="ml-6 mt-2"
            >
              {category.subcategories.map(subcategory => (
                <div key={subcategory.id} className="flex items-center mb-1">
                  <input
                    type="checkbox"
                    id={`subcategory-${subcategory.id}`}
                    checked={selectedSubcategories.includes(subcategory.id)}
                    onChange={() => handleSubcategoryChange(subcategory.id)}
                    className="mr-2 accent-teal-500 h-4 w-4"
                  />
                  <label htmlFor={`subcategory-${subcategory.id}`} className="text-gray-600 hover:text-teal-500 cursor-pointer transition-colors duration-200">{subcategory.name}</label>
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

const FilterSidebar = ({
  brands,
  categories,
  selectedBrands,
  selectedCategories,
  selectedSubcategories,
  priceRange,
  selectedRatings,
  onFilterChange,
  onClearAllFilters
}) => {
  const [searchBrand, setSearchBrand] = useState('');
  const [openSections, setOpenSections] = useState({
    brands: true,
    categories: true,
    priceRange: true,
    ratings: true
  });

  const toggleSection = (section) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const handleBrandChange = (brandId) => {
    const updatedBrands = selectedBrands.includes(brandId)
      ? selectedBrands.filter((id) => id !== brandId)
      : [...selectedBrands, brandId];
    onFilterChange('brands', updatedBrands);
  };

  const handleCategoryChange = (categoryId) => {
    const updatedCategories = selectedCategories.includes(categoryId)
      ? selectedCategories.filter((id) => id !== categoryId)
      : [...selectedCategories, categoryId];
    onFilterChange('categories', updatedCategories);
  };

  const handleSubcategoryChange = (subcategoryId) => {
    const updatedSubcategories = selectedSubcategories.includes(subcategoryId)
      ? selectedSubcategories.filter((id) => id !== subcategoryId)
      : [...selectedSubcategories, subcategoryId];
    onFilterChange('subcategories', updatedSubcategories);
  };

  const handleRatingChange = (rating) => {
    const updatedRatings = selectedRatings.includes(rating)
      ? selectedRatings.filter((r) => r !== rating)
      : [...selectedRatings, rating];
    onFilterChange('ratings', updatedRatings);
  };

  const filteredBrands = brands.filter((brand) =>
    brand.name.toLowerCase().includes(searchBrand.toLowerCase())
  );

  return (
    <div className="bg-teal-500 p-6 rounded-lg shadow-lg relative overflow-hidden">
      {/* Subtle background pattern */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute inset-0 bg-repeat" style={{ backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E\")" }}></div>
      </div>
      
      <div className="relative z-10">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white">Filters</h2>
          <button
            onClick={onClearAllFilters}
            className="bg-white text-teal-600 px-4 py-2 rounded-full hover:bg-teal-100 transition-all duration-300 ease-in-out flex items-center shadow-md hover:shadow-lg transform hover:-translate-y-1"
          >
            <X size={16} className="mr-2" />
            Clear All
          </button>
        </div>

        <FilterSection 
          title="Brands" 
          isOpen={openSections.brands}
          toggleOpen={() => toggleSection('brands')}
        >
          <div className="relative mb-4">
            <input
              type="text"
              placeholder="Search brands"
              className="w-full p-2 border border-teal-400 rounded-full pr-10 focus:ring-2 focus:ring-teal-400 focus:outline-none shadow-inner"
              value={searchBrand}
              onChange={(e) => setSearchBrand(e.target.value)}
            />
            <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-teal-500" size={18} />
          </div>
          <div className="max-h-60 overflow-y-auto custom-scrollbar">
            {filteredBrands.map(brand => (
              <div key={brand.id} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id={`brand-${brand.id}`}
                  checked={selectedBrands.includes(brand.id)}
                  onChange={() => handleBrandChange(brand.id)}
                  className="mr-2 accent-teal-500 h-4 w-4"
                />
                <label htmlFor={`brand-${brand.id}`} className="text-gray-700 hover:text-teal-600 cursor-pointer transition-colors duration-200">{brand.name}</label>
              </div>
            ))}
          </div>
        </FilterSection>

        <FilterSection 
          title="Categories" 
          isOpen={openSections.categories}
          toggleOpen={() => toggleSection('categories')}
        >
          {categories.map(category => (
            <CategoryItem
              key={category.id}
              category={category}
              selectedCategories={selectedCategories}
              selectedSubcategories={selectedSubcategories}
              handleCategoryChange={handleCategoryChange}
              handleSubcategoryChange={handleSubcategoryChange}
            />
          ))}
        </FilterSection>

        <FilterSection 
          title="Price Range" 
          isOpen={openSections.priceRange}
          toggleOpen={() => toggleSection('priceRange')}
        >
          <input
            type="range"
            min="0"
            max="150"
            value={priceRange[1]}
            onChange={(e) => onFilterChange('priceRange', [priceRange[0], parseInt(e.target.value)])}
            className="w-full accent-teal-500"
          />
          <div className="flex justify-between mt-2 text-teal-700">
            <span>£{priceRange[0]}</span>
            <span>£{priceRange[1]}</span>
          </div>
        </FilterSection>

        <FilterSection 
          title="Ratings" 
          isOpen={openSections.ratings}
          toggleOpen={() => toggleSection('ratings')}
        >
          {[5, 4, 3, 2, 1].map(rating => (
            <div key={rating} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={`rating-${rating}`}
                checked={selectedRatings.includes(rating)}
                onChange={() => handleRatingChange(rating)}
                className="mr-2 accent-teal-500 h-4 w-4"
              />
              <label htmlFor={`rating-${rating}`} className="flex items-center text-gray-700 hover:text-teal-600 cursor-pointer transition-colors duration-200">
                {[...Array(rating)].map((_, i) => (
                  <Star key={i} size={16} className="text-yellow-400 fill-current" />
                ))}
                <span className="ml-2">& Up</span>
              </label>
            </div>
          ))}
        </FilterSection>
      </div>
    </div>
  );
};

export default FilterSidebar;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import { register, forgotPassword, handleApiError } from '../services/api';
import { useAuth } from '../context/AuthContext';
import { ClipLoader } from 'react-spinners'; // Replace spinner import

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isForgottingPassword, setIsForgottingPassword] = useState(false);
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      if (isLogin) {
        await login({ username, password });
        // Wait for the next tick to ensure state has been updated
        setTimeout(() => navigate('/'), 0);
      } else {
        await register({ username, email, password, full_name: fullName, role: "user" });
        setIsLogin(true);
        alert('Account created successfully. Please log in.');
      }
    } catch (error) {
      handleApiError(error);
      setError(error.response?.data?.detail || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address');
      return;
    }
    setIsForgottingPassword(true);
    try {
      await forgotPassword(email);
      alert('If an account with this email exists, a password reset link has been sent.');
    } catch (error) {
      handleApiError(error);
      setError(error.response?.data?.detail || 'An error occurred');
    } finally {
      setIsForgottingPassword(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 border border-gray-300 rounded-lg shadow-xl">
        <div className="flex mb-8">
          <button
            className={`flex-1 py-2 ${isLogin ? 'text-teal-600 border-b-2 border-teal-600 font-bold' : 'text-gray-500 hover:text-teal-600'}`}
            onClick={() => setIsLogin(true)}
          >
            Log in
          </button>
          <button
            className={`flex-1 py-2 ${!isLogin ? 'text-teal-600 border-b-2 border-teal-600 font-bold' : 'text-gray-500 hover:text-teal-600'}`}
            onClick={() => setIsLogin(false)}
          >
            Register
          </button>
        </div>
        
        {error && <p className="text-red-500 mb-4">{error}</p>}
        
        <form onSubmit={handleSubmit}>
          {!isLogin && (
            <>
              <div className="mb-4">
                <label htmlFor="fullName" className="block text-gray-700 text-sm font-bold mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullName"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
                  placeholder="Enter your email"
                  required
                />
              </div>
            </>
          )}
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              placeholder="Enter your username"
              required
            />
          </div>
          <div className="mb-6 relative">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              placeholder={isLogin ? "Enter your password" : "Create a password"}
              required
            />
            <button
              type="button"
              className="absolute right-3 top-9 text-gray-500"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
          {isLogin && (
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center">
                <input type="checkbox" id="remember" className="mr-2" />
                <label htmlFor="remember" className="text-sm text-gray-600">Remember me</label>
              </div>
              <button 
                type="button"
                onClick={handleForgotPassword}
                disabled={isForgottingPassword}
                className="text-sm text-teal-600 hover:underline"
              >
                {isForgottingPassword ? 'Sending...' : 'Forgot your password?'}
              </button>
            </div>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-teal-500 text-white py-2 px-4 rounded-md hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50 flex items-center justify-center"
          >
            {isLoading ? (
              <>
                <ClipLoader size={20} color="#fff" /> Logging In...
              </>
            ) : (
              isLogin ? 'Log In' : 'Create Account'
            )}
          </button>
        </form>
        {isForgottingPassword && (
          <div className="mt-4 flex justify-center">
            <ClipLoader size={30} color="#38b2ac" /> {/* Show spinner while sending forgot password */}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthPage;

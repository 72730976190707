import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { X, Heart, ShoppingCart, Minus, Plus, Star } from 'lucide-react';
import { getStaticImageUrl, handleApiError } from '../services/api';
import { useCart } from '../context/CartContext';
import { useWishlist } from '../context/WishlistContext';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';

const QuickViewModal = ({ product, onClose }) => {
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCart();
  const { addToWishlist, removeFromWishlist, isInWishlist } = useWishlist();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const increaseQuantity = () => setQuantity((prev) => prev + 1);
  const decreaseQuantity = () => setQuantity((prev) => (prev > 1 ? prev - 1 : 1));

  const handleAddToCart = () => {
    if (!isAuthenticated) {
      navigate('/auth');
      onClose();
      return;
    }
    try {
      addToCart(product, quantity);
      onClose();
      toast.success('Product added to cart!');
    } catch (error) {
      console.error('Error adding to cart:', error);
      handleApiError(error);
      toast.error('Failed to add product to cart. Please try again.');
    }
  };

  const handleToggleWishlist = async () => {
    if (!isAuthenticated) {
      navigate('/auth');
      onClose();
      return;
    }
    try {
      if (isInWishlist(product.id)) {
        await removeFromWishlist(product.id);
        toast.success('Product removed from wishlist!');
      } else {
        await addToWishlist(product.id);
        toast.success('Product added to wishlist!');
      }
    } catch (error) {
      console.error('Error toggling wishlist:', error);
      handleApiError(error);
      toast.error('Failed to update wishlist. Please try again.');
    }
  };

  const imageUrl = getStaticImageUrl(product.image_url) || `https://via.placeholder.com/300x300?text=${encodeURIComponent(product.name)}`;

  const price = parseFloat(product.price) ? parseFloat(product.price).toFixed(2) : 'N/A';

  const modalContent = (
    <AnimatePresence>
      <motion.div 
        className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50 backdrop-blur-sm"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div 
          className="bg-white p-8 rounded-lg max-w-4xl w-full shadow-2xl relative transform transition-transform duration-300"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ type: 'spring', damping: 15 }}
        >
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors duration-200"
          >
            <X size={24} />
          </button>
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 mb-4 md:mb-0">
              <motion.img
                src={imageUrl}
                alt={product.name}
                className="w-full h-auto object-cover rounded-lg shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-8">
              <motion.h2 
                className="text-3xl font-bold mb-4 text-gray-800"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                {product.name}
              </motion.h2>
              <motion.p 
                className="text-2xl font-bold text-teal-600 mb-2"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                £{price}
              </motion.p>
              <motion.p 
                className="text-gray-600 mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                Brand: {typeof product.brand === 'string' ? product.brand : product.brand.name}
              </motion.p>
              <motion.div 
                className="flex items-center mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
              >
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    size={20}
                    className={i < Math.floor(product.rating) ? 'text-yellow-400 fill-current' : 'text-gray-300'}
                  />
                ))}
                <span className="ml-2 text-sm text-gray-600">({product.reviews} reviews)</span>
              </motion.div>
              <motion.p 
                className="mb-4 text-gray-700"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7 }}
              >
                {product.shortDescription}
              </motion.p>
              <motion.div 
                className="flex items-center space-x-4 mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
              >
                <p className="font-semibold text-gray-800">Quantity:</p>
                <div className="flex items-center border rounded-lg overflow-hidden shadow-sm">
                  <motion.button
                    onClick={decreaseQuantity}
                    className="px-3 py-2 bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
                    whileHover={{ backgroundColor: "#E5E7EB" }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Minus size={16} />
                  </motion.button>
                  <span className="px-4 py-2 border-l border-r">{quantity}</span>
                  <motion.button
                    onClick={increaseQuantity}
                    className="px-3 py-2 bg-gray-100 hover:bg-gray-200 transition-colors duration-200"
                    whileHover={{ backgroundColor: "#E5E7EB" }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Plus size={16} />
                  </motion.button>
                </div>
              </motion.div>
              <motion.div 
                className="flex space-x-4 mt-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.9 }}
              >
                <motion.button 
                  onClick={handleAddToCart}
                  className="flex-1 bg-teal-500 text-white py-3 px-6 rounded-lg hover:bg-teal-600 transition-colors duration-200 flex items-center justify-center shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <ShoppingCart size={20} className="mr-2" />
                  Add to Cart
                </motion.button>
                <motion.button 
                  onClick={handleToggleWishlist}
                  className="bg-gray-200 text-gray-700 py-3 px-6 rounded-lg hover:bg-gray-300 transition-colors duration-200 shadow-lg"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Heart size={20} className={isInWishlist(product.id) ? "text-red-500 fill-current" : ""} />
                </motion.button>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

export default QuickViewModal;
import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LayoutDashboard, Package, FileText, Users, Settings, ShoppingCart, Tag } from 'lucide-react';
import ProductManagement from '../components/AdminComponents/ProductManagement';
import BlogManagement from '../components/AdminComponents/BlogManagement';
import UserManagement from '../components/AdminComponents/UserManagement';
import SettingsManagement from '../components/AdminComponents/SettingsManagement';
import OrderManagement from '../components/AdminComponents/OrderManagement';
import CouponManagement from '../components/AdminComponents/CouponManagement';

const AdminDashboard = () => {
  const { user, isAuthenticated } = useAuth();
  const [activeTab, setActiveTab] = useState('products');

  if (!isAuthenticated || user.role !== 'admin') {
    return <Navigate to="/login" replace />;
  }

  const tabs = [
    { id: 'products', name: 'Products', icon: Package, component: ProductManagement },
    { id: 'blogs', name: 'Blogs', icon: FileText, component: BlogManagement },
    { id: 'users', name: 'Users', icon: Users, component: UserManagement },
    { id: 'orders', name: 'Orders', icon: ShoppingCart, component: OrderManagement },
    { id: 'coupons', name: 'Coupons', icon: Tag, component: CouponManagement },
    { id: 'settings', name: 'Settings', icon: Settings, component: SettingsManagement },
  ];

  const ActiveComponent = tabs.find(tab => tab.id === activeTab).component;

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <motion.div
        initial={{ x: -250 }}
        animate={{ x: 0 }}
        className="w-64 bg-white shadow-lg"
      >
        <div className="p-4">
          <h1 className="text-2xl font-bold text-teal-600 flex items-center">
            <LayoutDashboard className="mr-2" />
            Admin Panel
          </h1>
        </div>
        <nav className="mt-8">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center w-full px-4 py-2 mt-2 text-sm font-semibold transition-colors duration-200 ${
                activeTab === tab.id ? 'bg-teal-500 text-white' : 'hover:bg-teal-100 text-gray-900'
              }`}
            >
              <tab.icon className="mr-2" size={18} />
              {tab.name}
            </button>
          ))}
        </nav>
      </motion.div>

      {/* Main content */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100"
      >
        <div className="container mx-auto px-6 py-8">
          <h3 className="text-3xl font-medium text-gray-700">
            {tabs.find(tab => tab.id === activeTab).name}
          </h3>
          <div className="mt-8">
            <ActiveComponent />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AdminDashboard;
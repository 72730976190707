import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { useAuth } from '../context/AuthContext';
import { createOrder, getUserAddresses, createAddress, applyCoupon } from '../services/api';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { motion } from 'framer-motion';
import { ClipLoader } from 'react-spinners'; // Replace spinner import

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().matches(/^\+?1?\d{9,15}$/, 'Invalid phone number').required('Phone number is required'),
  shipping_address_id: yup.number().nullable(),
  billing_address_id: yup.number().nullable(),
  new_shipping_address: yup.boolean(),
  new_billing_address: yup.boolean(),
  payment_method: yup.string().oneOf(['bank_transfer', 'bitcoin']).required('Payment method is required'),
  delivery_method: yup.string().oneOf(['standard', 'express']).required('Delivery method is required'),
});

const CheckoutPage = () => {
  const { cart, getCartTotal, clearCart } = useCart();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [sameAsBilling, setSameAsBilling] = useState(true);
  const [deliveryMethod, setDeliveryMethod] = useState('standard');
  const [deliveryCost, setDeliveryCost] = useState(3.5);
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
  const [isFetchingAddresses, setIsFetchingAddresses] = useState(false);
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false);
  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: user.email,
      new_shipping_address: false,
      new_billing_address: false,
      shipping_address_id: null,
      billing_address_id: null,
      payment_method: 'bank_transfer',
      delivery_method: 'standard',
    },
  });

  const subtotal = getCartTotal();

  useEffect(() => {
    fetchUserAddresses();
  }, []);

  useEffect(() => {
    if (subtotal >= 50) {
      setDeliveryCost(0);
    } else if (deliveryMethod === 'express') {
      setDeliveryCost(10);
    } else {
      setDeliveryCost(3.5);
    }
  }, [subtotal, deliveryMethod]);

  const fetchUserAddresses = async () => {
    setIsFetchingAddresses(true);
    try {
      const userAddresses = await getUserAddresses();
      setAddresses(userAddresses);
      
      const defaultShippingAddress = userAddresses.find(addr => addr.is_default && addr.address_type === 'shipping');
      const defaultBillingAddress = userAddresses.find(addr => addr.is_default && addr.address_type === 'billing');
      
      if (defaultShippingAddress) {
        setValue('shipping_address_id', defaultShippingAddress.id);
      }
      if (defaultBillingAddress) {
        setValue('billing_address_id', defaultBillingAddress.id);
      }
    } catch (error) {
      console.error('Error fetching user addresses:', error);
      toast.error('Failed to load your addresses. Please try again.');
    } finally {
      setIsFetchingAddresses(false);
    }
  };

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      toast.error('Please enter a coupon code');
      return;
    }

    setIsApplyingCoupon(true);
    try {
      const result = await applyCoupon(couponCode);
      setAppliedCoupon({
        code: result.coupon_code,
        discountPercentage: result.discount_percentage
      });
      setDiscount(result.discount_percentage);
      toast.success(`Coupon ${result.coupon_code} applied successfully! ${result.discount_percentage}% discount`);
    } catch (error) {
      console.error('Error applying coupon:', error);
      toast.error(error.response?.data?.detail || 'Failed to apply coupon. Please try again.');
    } finally {
      setIsApplyingCoupon(false);
    }
  };
  const handleRemoveCoupon = () => {
    setAppliedCoupon(null);
    setDiscount(0);
    setCouponCode('');
    toast.info('Coupon removed');
  };
  const total = subtotal + deliveryCost - (subtotal * (discount / 100));

  const onSubmit = async (data) => {
    setIsSubmittingOrder(true);
    if (!data.new_shipping_address && !data.shipping_address_id) {
      toast.error('Please select a shipping address or add a new one.');
      setIsSubmittingOrder(false);
      return;
    }
  
    if (!sameAsBilling && !data.new_billing_address && !data.billing_address_id) {
      toast.error('Please select a billing address or add a new one.');
      setIsSubmittingOrder(false);
      return;
    }
  
    try {
      let shippingAddressId = data.shipping_address_id;
      let billingAddressId = sameAsBilling ? data.shipping_address_id : data.billing_address_id;
  
      if (data.new_shipping_address) {
        const newShippingAddress = await createAddress({
          ...data.new_shipping_address,
          address_type: 'shipping'
        });
        shippingAddressId = newShippingAddress.id;
      }
  
      if (!sameAsBilling && data.new_billing_address) {
        const newBillingAddress = await createAddress({
          ...data.new_billing_address,
          address_type: 'billing'
        });
        billingAddressId = newBillingAddress.id;
      }
      
      const orderData = {
        shipping_address_id: shippingAddressId,
        billing_address_id: billingAddressId,
        items: cart.map(item => ({
          product_id: item.product_id,
          quantity: item.quantity,
          price_at_time_of_order: parseFloat(item.product_price)
        })),
        total_amount: total,
        delivery_cost: deliveryCost,
        payment_method: data.payment_method,
        delivery_method: data.delivery_method,
        coupon_code: couponCode,
      };
  
      console.log('Sending order data:', orderData);
      const response = await createOrder(orderData);
      console.log('Received order response:', response);

      const createdOrderResponse = response.data;

      if (!createdOrderResponse || typeof createdOrderResponse !== 'object') {
        throw new Error('Invalid response from server');
      }

      clearCart();
      toast.success('Order placed successfully!');

      // Redirect based on payment method
      if (createdOrderResponse.payment.payment.payment_method === 'bitcoin') {
        navigate('/bitcoin-payment', { 
          state: { 
            orderId: createdOrderResponse.id, 
            bitcoinDetails: createdOrderResponse.bitcoin_details,
            totalAmount: createdOrderResponse.total_amount
          }
        });
      } else if (createdOrderResponse.payment.payment.payment_method === 'bank_transfer') {
        navigate('/bank-transfer-payment', { 
          state: { 
            orderId: createdOrderResponse.id, 
            bankTransferDetails: createdOrderResponse.bank_transfer_details,
            totalAmount: createdOrderResponse.total_amount
          }
        });
      } else {
        console.error('Unknown payment method:', createdOrderResponse.payment.payment.payment_method);
        toast.error('Unknown payment method. Please contact support.');
      }
    } catch (error) {
      console.error('Error creating order:', error);
      console.error('Error details:', error.response?.data);
      toast.error('Failed to place order. Please try again.');
    } finally {
      setIsSubmittingOrder(false);
    }
  };

  const renderAddressFields = (prefix) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-4"
    >
      <input {...register(`${prefix}.full_name`)} placeholder="Full Name" className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent" />
      {errors[prefix]?.full_name && <p className="text-red-500 text-sm">{errors[prefix].full_name.message}</p>}
      
      <input {...register(`${prefix}.address_line1`)} placeholder="Address Line 1" className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent" />
      {errors[prefix]?.address_line1 && <p className="text-red-500 text-sm">{errors[prefix].address_line1.message}</p>}
      
      <input {...register(`${prefix}.address_line2`)} placeholder="Address Line 2 (optional)" className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent" />
      
      <div className="grid grid-cols-2 gap-4">
        <div>
          <input {...register(`${prefix}.city`)} placeholder="City" className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent" />
          {errors[prefix]?.city && <p className="text-red-500 text-sm">{errors[prefix].city.message}</p>}
        </div>
        <div>
          <input {...register(`${prefix}.state`)} placeholder="State" className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent" />
          {errors[prefix]?.state && <p className="text-red-500 text-sm">{errors[prefix].state.message}</p>}
        </div>
      </div>
      
      <div className="grid grid-cols-2 gap-4">
        <div>
          <input {...register(`${prefix}.postal_code`)} placeholder="Postal Code" className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent" />
          {errors[prefix]?.postal_code && <p className="text-red-500 text-sm">{errors[prefix].postal_code.message}</p>}
        </div>
        <div>
          <select {...register(`${prefix}.country`)} className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent">
            <option value="GB">United Kingdom</option>
            <option value="US">United States</option>
            <option value="CA">Canada</option>
            <option value="AU">Australia</option>
            <option value="NZ">New Zealand</option>
          </select>
          {errors[prefix]?.country && <p className="text-red-500 text-sm">{errors[prefix].country.message}</p>}
        </div>
      </div>
    </motion.div>
  );

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-50">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Checkout</h1>
      {cart.length === 0 ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center bg-white p-8 rounded-lg shadow-md"
        >
          <p className="mb-4 text-lg text-gray-600">Your cart is empty. Please add items to your cart before checking out.</p>
          <button
            onClick={() => navigate('/shop')}
            className="bg-teal-500 text-white py-2 px-6 rounded-full hover:bg-teal-600 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Continue Shopping
          </button>
        </motion.div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white p-8 rounded-lg shadow-md"
          >
            <h2 className="text-2xl font-semibold mb-6 text-gray-800">Contact Information</h2>
            <div className="space-y-4">
              <input {...register('email')} placeholder="Email" className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent" />
              {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
              
              <input {...register('phone')} placeholder="Phone" className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent" />
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
            </div>

            <h2 className="text-2xl font-semibold mb-6 mt-8 text-gray-800">Shipping Address</h2>
            {addresses.length > 0 && (
              <div className="mb-4">
                <select {...register('shipping_address_id')} className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent">
                  <option value="">Select a shipping address</option>
                  {addresses.filter(addr => addr.address_type === 'shipping').map(addr => (
                    <option key={addr.id} value={addr.id}>
                      {addr.full_name}, {addr.address_line1}, {addr.city}
                    </option>
                  ))}
                </select>
                {errors.shipping_address_id && <p className="text-red-500 text-sm">{errors.shipping_address_id.message}</p>}
              </div>
            )}
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  {...register('new_shipping_address')}
                  className="form-checkbox text-teal-500 h-5 w-5"
                />
                <span className="ml-2 text-gray-700">Add a new shipping address</span>
              </label>
            </div>
            {watch('new_shipping_address') && renderAddressFields('new_shipping_address')}

            <div className="mt-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={sameAsBilling}
                  onChange={() => setSameAsBilling(!sameAsBilling)}
                  className="form-checkbox text-teal-500 h-5 w-5"
                />
                <span className="ml-2 text-gray-700">Billing address same as shipping</span>
              </label>
            </div>

            {!sameAsBilling && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="text-2xl font-semibold mb-6 mt-8 text-gray-800">Billing Address</h2>
                {addresses.length > 0 && (
                  <div className="mb-4">
                    <select {...register('billing_address_id')} className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent">
                      <option value="">Select a billing address</option>
                      {addresses.filter(addr => addr.address_type === 'billing').map(addr => (
                        <option key={addr.id} value={addr.id}>
                          {addr.full_name}, {addr.address_line1}, {addr.city}
                        </option>
                      ))}
                    </select>
                    {errors.billing_address_id && <p className="text-red-500 text-sm">{errors.billing_address_id.message}</p>}
                  </div>
                )}
                <div className="mb-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      {...register('new_billing_address')}
                      className="form-checkbox text-teal-500 h-5 w-5"
                    />
                    <span className="ml-2 text-gray-700">Add a new billing address</span>
                  </label>
                </div>
                {watch('new_billing_address') && renderAddressFields('new_billing_address')}
              </motion.div>
            )}

            <h2 className="text-2xl font-semibold mb-6 mt-8 text-gray-800">Delivery Method</h2>
            <div className="space-y-4">
              <label className="flex items-center p-4 border rounded-md hover:bg-gray-50 transition duration-300 ease-in-out">
                <input
                  type="radio"
                  {...register('delivery_method')}
                  value="standard"
                  checked={deliveryMethod === 'standard'}
                  onChange={() => setDeliveryMethod('standard')}
                  className="form-radio text-teal-500 h-5 w-5"
                />
                <span className="ml-2">
                  <span className="font-semibold">Standard Royal Mail</span>
                  <span className="block text-sm text-gray-500">24 hours tracked (£3.50)</span>
                </span>
              </label>
              <label className="flex items-center p-4 border rounded-md hover:bg-gray-50 transition duration-300 ease-in-out">
                <input
                  type="radio"
                  {...register('delivery_method')}
                  value="express"
                  checked={deliveryMethod === 'express'}
                  onChange={() => setDeliveryMethod('express')}
                  className="form-radio text-teal-500 h-5 w-5"
                />
                <span className="ml-2">
                  <span className="font-semibold">Express Special Delivery</span>
                  <span className="block text-sm text-gray-500">(£10.00)</span>
                </span>
              </label>
              {subtotal >= 50 && (
                <p className="text-green-600 font-semibold mt-2">Free standard delivery on orders over £50!</p>
              )}
            </div>
            {errors.delivery_method && <p className="text-red-500 text-sm mt-2">{errors.delivery_method.message}</p>}

            <h2 className="text-2xl font-semibold mb-6 mt-8 text-gray-800">Payment Method</h2>
            <select {...register('payment_method')} className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent">
              <option value="bank_transfer">Bank Transfer</option>
              <option value="bitcoin">Bitcoin</option>
            </select>
            {errors.payment_method && <p className="text-red-500 text-sm mt-2">{errors.payment_method.message}</p>}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white p-8 rounded-lg shadow-md"
          >
            <h2 className="text-2xl font-semibold mb-6 text-gray-800">Order Summary</h2>
            <div className="space-y-4 mb-6">
              {cart.map((item) => (
                <div key={item.id} className="flex justify-between items-center">
                  <div>
                    <span className="font-semibold">{item.product_name}</span>
                    <span className="text-gray-500 text-sm block">Quantity: {item.quantity}</span>
                  </div>
                  <span className="font-semibold">£{(item.product_price * item.quantity).toFixed(2)}</span>
                </div>
              ))}
            </div>
            <div className="border-t pt-4 mt-4">
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-600">Subtotal</span>
                <span className="font-semibold">£{subtotal.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-600">Delivery</span>
                <span className="font-semibold">£{deliveryCost.toFixed(2)}</span>
              </div>
              {discount > 0 && (
                <div className="flex justify-between items-center mb-2 text-green-600">
                  <span>Discount</span>
                  <span className="font-semibold">-£{(subtotal * (discount / 100)).toFixed(2)}</span>
                </div>
              )}
              <div className="flex justify-between items-center font-bold text-lg mt-4">
                <span>Total</span>
                <span>£{total.toFixed(2)}</span>
              </div>
            </div>

            <div className="mt-8">
        <h3 className="text-lg font-semibold mb-2 text-gray-800">Apply Coupon</h3>
        {appliedCoupon ? (
          <div className="flex items-center justify-between bg-green-100 p-2 rounded">
            <span>
              Coupon <strong>{appliedCoupon.code}</strong> applied ({appliedCoupon.discountPercentage}% off)
            </span>
            <button
              onClick={handleRemoveCoupon}
              className="text-red-500 hover:text-red-700"
            >
              Remove
            </button>
          </div>
        ) : (
          <div className="flex space-x-2">
            <input
              type="text"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              placeholder="Enter coupon code"
              className="flex-grow px-4 py-2 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
            />
            <button
              type="button"
              onClick={handleApplyCoupon}
              disabled={isApplyingCoupon}
              className={`bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition duration-300 ease-in-out ${
                isApplyingCoupon ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isApplyingCoupon ? (
                <ClipLoader size={20} color="#fff" /> // Show spinner inside button
              ) : (
                'Apply'
              )}
            </button>
          </div>
        )}
      </div>

            <button
              type="submit"
              disabled={isSubmittingOrder}
              className="bg-teal-500 text-white py-3 px-6 rounded-md hover:bg-teal-600 transition duration-300 ease-in-out transform hover:scale-105 w-full mt-8 text-lg font-semibold"
            >
              {isSubmittingOrder ? (
                <div className="flex items-center justify-center">
                  <ClipLoader size={20} color="#fff" /> Placing Order...
                </div>
              ) : (
                'Place Order'
              )}
            </button>
          </motion.div>
        </form>
      )}
    </div>
  );
};

export default CheckoutPage;
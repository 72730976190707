import React, { createContext, useContext, useState, useEffect } from 'react';
import { getWishlist, addToWishlist as apiAddToWishlist, removeFromWishlist as apiRemoveFromWishlist } from '../services/api';
import { useAuth } from './AuthContext';
import { toast } from 'react-toastify';

const WishlistContext = createContext();

export const useWishlist = () => useContext(WishlistContext);

export const WishlistProvider = ({ children }) => {
  const [wishlist, setWishlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      fetchWishlist();
    } else {
      setWishlist([]);
      setLoading(false);
    }
  }, [isAuthenticated]);

  const fetchWishlist = async () => {
    if (!isAuthenticated) return;
    try {
      setLoading(true);
      const response = await getWishlist();
      // console.log('Wishlist response:', response);
      if (Array.isArray(response.data)) {
        setWishlist(response.data);
      } else if (response.data && Array.isArray(response.data.items)) {
        setWishlist(response.data.items);
      } else {
        console.error('Unexpected wishlist data structure:', response.data);
        setWishlist([]);
      }
    } catch (error) {
      console.error('Error fetching wishlist:', error);
      toast.error('Failed to fetch wishlist. Please try again.');
      setWishlist([]);
    } finally {
      setLoading(false);
    }
  };

  const addToWishlist = async (productId) => {
    if (!isAuthenticated) return;
    try {
      if (!isInWishlist(productId)) {
        await apiAddToWishlist(productId);
        await fetchWishlist();
        toast.success('Product added to wishlist!');
      } else {
        toast.info('Product is already in your wishlist.');
      }
    } catch (error) {
      console.error('Error adding to wishlist:', error);
      toast.error('Failed to add product to wishlist. Please try again.');
    }
  };

  const removeFromWishlist = async (productId) => {
    if (!isAuthenticated) return;
    try {
      await apiRemoveFromWishlist(productId);
      setWishlist(currentWishlist => currentWishlist.filter(item => item.product_id !== productId));
      toast.success('Product removed from wishlist!');
    } catch (error) {
      console.error('Error removing from wishlist:', error);
      toast.error('Failed to remove product from wishlist. Please try again.');
    }
  };

  const isInWishlist = (productId) => {
    return wishlist.some(item => item.product_id === productId);
  };

  return (
    <WishlistContext.Provider value={{
      wishlist,
      loading,
      addToWishlist,
      removeFromWishlist,
      isInWishlist,
      isAuthenticated
    }}>
      {children}
    </WishlistContext.Provider>
  );
};
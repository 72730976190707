import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  ShoppingCart, 
  User, 
  Menu, 
  X, 
  Gift, 
  Settings, 
  ChevronDown, 
  LogOut, 
  ShoppingBag, 
  Heart, 
  CreditCard, 
  Truck, 
  FileText, 
  HelpCircle, 
  Home, 
  Package 
} from 'lucide-react';
import { getCategories, handleApiError } from '../services/api';
import { useAuth } from '../context/AuthContext';
import { useCart } from '../context/CartContext';
import { motion, AnimatePresence } from 'framer-motion';
import SearchBar from './SearchBar';
import CartDropdown from './CartDropDown';
import Dropdown from './DropDown';
import UserDropdown from './UserDropDown';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { user, isAuthenticated, loading, logout } = useAuth();
  const { cart, getCartItemsCount } = useCart();
  const navigate = useNavigate();

  const fetchCategories = useCallback(async () => {
    try {
      const response = await getCategories();
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    fetchCategories();

    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchCategories]);

  const handleCategoryClick = useCallback((categoryId) => {
    navigate(`/shop?categories=${categoryId}`);
    setIsSidebarOpen(false);
  }, [navigate]);

  const handleSubcategoryClick = useCallback((subcategoryId) => {
    navigate(`/shop?subcategories=${subcategoryId}`);
    setIsSidebarOpen(false);
  }, [navigate]);

  const handleLogout = useCallback(() => {
    logout();
    navigate('/');
    setIsSidebarOpen(false);
  }, [logout, navigate]);

  const sidebarAnimation = {
    open: { x: 0, transition: { type: 'spring', stiffness: 300, damping: 30 } },
    closed: { x: '100%', transition: { type: 'spring', stiffness: 300, damping: 30 } },
  };

  const MobileDropdown = ({ category }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="w-full">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-between w-full py-2 px-4 text-white hover:bg-teal-700 rounded-lg transition-colors duration-200"
        >
          {category.name}
          <ChevronDown size={16} className={`ml-1 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
        </button>
        {isOpen && (
          <div className="ml-4">
            {category.subcategories.map((subcategory) => (
              <button
                key={subcategory.id}
                onClick={() => handleSubcategoryClick(subcategory.id)}
                className="block w-full text-left px-4 py-2 text-teal-100 hover:bg-teal-600 transition-colors duration-200"
              >
                {subcategory.name}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderMobileSidebar = () => (
    <AnimatePresence>
      {isSidebarOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black z-40"
            onClick={() => setIsSidebarOpen(false)}
          />
          <motion.div
            className="fixed top-0 right-0 w-80 h-full bg-gradient-to-b from-teal-600 to-teal-800 z-50 overflow-y-auto shadow-lg"
            variants={sidebarAnimation}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <div className="p-6 text-white">
              <button onClick={() => setIsSidebarOpen(false)} className="absolute top-4 right-4 text-white hover:text-teal-200 transition-colors duration-200">
                <X size={24} />
              </button>
              <div className="mt-8 space-y-6">
                {isAuthenticated && user && (
                  <Link to="/profile" className="flex items-center space-x-4 mb-6 hover:bg-teal-700 p-3 rounded-lg transition-colors duration-200">
                    <div className="w-12 h-12 rounded-full bg-teal-400 flex items-center justify-center text-teal-800 text-xl font-bold">
                      {user.name ? user.name.charAt(0).toUpperCase() : ''}
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg">{user.name || 'User'}</h3>
                      <p className="text-sm text-teal-200">{user.email || ''}</p>
                    </div>
                  </Link>
                )}
                <div className="mb-6">
                  <SearchBar isMobile={true} />
                </div>
                <nav className="space-y-2">
                  {[
                    { to: "/", icon: Home, label: "Home" },
                    { to: "/shop", icon: ShoppingCart, label: "Shop" },
                    { to: "/cart", icon: ShoppingCart, label: "Cart" },
                    { to: "/brands", icon: Package, label: "Brands" },
                    { to: "/blog", icon: FileText, label: "Blog" },
                    { to: "/contact", icon: HelpCircle, label: "Contact" },
                    { to: "/delivery-info", icon: Truck, label: "Delivery Info" },
                    { to: "/wishlist", icon: Heart, label: "Wishlist" },
                  ].map((item) => (
                    <Link 
                      key={item.to} 
                      to={item.to} 
                      className="flex items-center py-2 px-4 text-white hover:bg-teal-700 rounded-lg transition-colors duration-200" 
                      onClick={() => setIsSidebarOpen(false)}
                    >
                      <item.icon size={20} className="mr-3" />
                      {item.label}
                      {item.to === "/cart" && (
                        <span className="ml-auto bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                          {getCartItemsCount()}
                        </span>
                      )}
                    </Link>
                  ))}
                </nav>
                <div className="mt-6">
                  <h4 className="text-teal-200 font-semibold mb-2">Categories</h4>
                  {categories.map((category) => (
                    <MobileDropdown key={category.id} category={category} />
                  ))}
                </div>
                {isAuthenticated && user && user.role === 'admin' && (
                  <Link to="/admin" className="flex items-center py-2 px-4 text-white hover:bg-teal-700 rounded-lg transition-colors duration-200" onClick={() => setIsSidebarOpen(false)}>
                    <Settings size={20} className="mr-3" />
                    Admin Dashboard
                  </Link>
                )}
                {isAuthenticated ? (
                  <button
                    onClick={handleLogout}
                    className="flex items-center py-2 px-4 text-red-400 hover:bg-red-700 hover:text-white rounded-lg transition-colors duration-200 w-full"
                  >
                    <LogOut size={20} className="mr-3" />
                    Logout
                  </button>
                ) : (
                  <Link to="/auth" className="flex items-center py-2 px-4 text-white hover:bg-teal-700 rounded-lg transition-colors duration-200" onClick={() => setIsSidebarOpen(false)}>
                    <User size={20} className="mr-3" />
                    Log in / Register
                  </Link>
                )}
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );

  return (
    <motion.header
      className={`bg-gradient-to-r from-teal-600 to-teal-500 text-white ${isSticky ? 'sticky top-0 z-50 shadow-lg' : ''}`}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-3xl font-bold flex items-center group relative">
            <motion.span
              className="text-white"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              UK
            </motion.span>
            <motion.span
              className="text-teal-100"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              STEROIDS
            </motion.span>
            <motion.span
              className="text-white"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              STORE
            </motion.span>
          </Link>
          <div className="hidden md:flex items-center space-x-4 flex-grow justify-end">
            <motion.div
              className="flex-grow max-w-3xl mx-4"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <SearchBar isMobile={false} />
            </motion.div>
            <div className="flex items-center space-x-4">
              <div className="relative">
                <button
                  className="text-white hover:text-teal-100 transition-colors duration-200"
                  onClick={() => setIsCartOpen(!isCartOpen)}
                >
                  <ShoppingCart size={24} />
                  <motion.span
                    className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: 'spring', stiffness: 500, damping: 30 }}
                  >
                    {getCartItemsCount()}
                  </motion.span>
                </button>
                {isCartOpen && <CartDropdown onClose={() => setIsCartOpen(false)} />}
              </div>
              {loading ? (
                <div className="text-white">Loading...</div>
              ) : isAuthenticated ? (
                <UserDropdown />
              ) : (
                <Link to="/auth" className="flex items-center text-white hover:text-teal-100 transition-colors duration-200">
                  <User className="mr-1" />
                  <span>Log in / Register</span>
                </Link>
              )}
            </div>
          </div>
          <button
            className="md:hidden text-white hover:text-teal-100 transition-colors duration-200 focus:outline-none"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            <Menu size={24} />
          </button>
        </div>
      </div>
      <nav className="bg-teal-600">
        <div className="container mx-auto px-4">
          <ul className="hidden md:flex justify-left items-center py-2 space-x-10">
            <li><Link to="/" className="text-white hover:text-teal-100 transition-colors duration-200">Home</Link></li>
            <li><Link to="/shop" className="text-white hover:text-teal-100 transition-colors duration-200">ALL</Link></li>
            {/* <li><Link to="/brands" className="text-white hover:text-teal-100 transition-colors duration-200">Brands</Link></li> */}
            {categories.map((category) => (
              <li key={category.id}>
                <Dropdown 
                  category={category}
                  onCategoryClick={handleCategoryClick}
                  onSubcategoryClick={handleSubcategoryClick}
                />
              </li>
            ))}
            <li><Link to="/blog" className="text-white hover:text-teal-100 transition-colors duration-200">Blog</Link></li>
            <li><Link to="/contact" className="text-white hover:text-teal-100 transition-colors duration-200">Contact</Link></li>
            <li><Link to="/delivery-info" className="text-white hover:text-teal-100 transition-colors duration-200">Delivery Info</Link></li>
            {isAuthenticated && user && user.role === 'admin' && (
              <li>
                <Link to="/admin" className="text-white hover:text-teal-100 transition-colors duration-200 flex items-center">
                  <Settings size={16} className="mr-1" />
                  Admin
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
      {renderMobileSidebar()}
      <motion.div
        className="bg-yellow-400 text-teal-800 py-2 px-4 text-center"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.5 }}
      >
        <span className="flex items-center justify-center">
          <Gift size={16} className="mr-2" />
          Special Offer: Free shipping on orders over £50! Limited time only.
        </span>
      </motion.div>
    </motion.header>
  );
};

export default Header;
import React, { useState } from 'react';
import { Star, Eye, Heart, ShoppingCart } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import QuickViewModal from './QuickViewModal';
import { handleApiError, getStaticImageUrl } from '../services/api';
import { useCart } from '../context/CartContext';
import { useWishlist } from '../context/WishlistContext';
import { useAuth } from '../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';

const ProductCard = ({ product }) => {
  const [showQuickView, setShowQuickView] = useState(false);
  const { addToCart } = useCart();
  const { addToWishlist, removeFromWishlist, isInWishlist } = useWishlist();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleAddToCart = async (e) => {
    e.preventDefault();
    try {
      await addToCart(product, 1);
      toast.success('Product added to cart!');
    } catch (error) {
      toast.error('Failed to add product to cart');
      handleApiError(error);
    }
  };

  const handleToggleWishlist = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      navigate('/auth');
      return;
    }
    try {
      if (isInWishlist(product.id)) {
        await removeFromWishlist(product.id);
        toast.success('Removed from wishlist');
      } else {
        await addToWishlist(product.id);
        toast.success('Added to wishlist');
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const renderStars = () => (
    <div className="flex items-center">
      {[...Array(5)].map((_, i) => (
        <Star
          key={i}
          size={16}
          className={i < Math.floor(product.rating) ? "text-yellow-400 fill-current" : "text-gray-300"}
        />
      ))}
      <span className="ml-2 text-xs text-gray-600">({product.num_reviews})</span>
    </div>
  );

  const formatPrice = (price) => {
    return typeof price === 'number' ? price.toFixed(2) : parseFloat(price).toFixed(2);
  };

  const imageUrl = product && product.image_urls 
    ? getStaticImageUrl(product.image_urls) 
    : `https://via.placeholder.com/300x300?text=${encodeURIComponent(product?.name || 'Product')}`;

  return (
    <motion.div 
      className="relative bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 group"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Link to={`/product/${product.id}`} className="block relative aspect-square overflow-hidden">
        <img 
          src={imageUrl} 
          alt={product.name} 
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
        {product.stock_quantity > 0 && (
          <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full">
            In Stock
          </span>
        )}
        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity duration-300" />
      </Link>

      <div className="p-4">
        <Link to={`/product/${product.id}`} className="block">
          <h3 className="font-semibold text-lg mb-1 text-gray-800 hover:text-teal-600 transition-colors duration-200 line-clamp-2">
            {product.name}
          </h3>
        </Link>
        <p className="text-sm text-gray-600 mb-2">{product.brand?.name || 'Unknown Brand'}</p>
        {renderStars()}
        <p className="font-bold text-xl mt-2 text-teal-600">£{formatPrice(product.price)}</p>
      </div>

      <div className="absolute top-2 right-2 flex flex-col space-y-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <motion.button
          onClick={(e) => {
            e.preventDefault();
            setShowQuickView(true);
          }}
          className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-200"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Eye size={20} className="text-gray-600" />
        </motion.button>
        <motion.button
          onClick={handleToggleWishlist}
          className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-200"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Heart size={20} className={isInWishlist(product.id) ? "text-red-500 fill-current" : "text-gray-600"} />
        </motion.button>
      </div>

      <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-white via-white to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300 transform translate-y-full group-hover:translate-y-0">
        <motion.button
          onClick={handleAddToCart}
          className="w-full bg-teal-500 text-white py-2 px-4 rounded-full flex items-center justify-center hover:bg-teal-600 transition-colors duration-200"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <ShoppingCart size={20} className="mr-2" />
          Add to Cart
        </motion.button>
      </div>

      <AnimatePresence>
        {showQuickView && (
          <QuickViewModal 
            product={product} 
            onClose={() => setShowQuickView(false)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ProductCard;
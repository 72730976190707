import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Star, ShoppingCart, Heart, Share2, Check, ChevronLeft, ChevronRight, User } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Cookies from 'js-cookie';
import ProductCard from '../components/ProductCard';
import { getProduct, getRelatedProducts, getStaticImageUrl, handleApiError } from '../services/api';
import { useWishlist } from '../context/WishlistContext';
import { useCart } from '../context/CartContext';
import { toast } from 'react-toastify';

const ProductDisplayPage = () => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const { id } = useParams();
  const [quantity, setQuantity] = useState(1);
  const [activeTab, setActiveTab] = useState('description');
  const [activeImage, setActiveImage] = useState(0);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [sortBy, setSortBy] = useState('newest');
  const [filterRating, setFilterRating] = useState(0);
  const { addToWishlist, removeFromWishlist, isInWishlist } = useWishlist();
  const { addToCart } = useCart();

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);
      try {
        const productData = await getProduct(id);
        console.log(productData.data);
        setProduct(productData.data);

        const relatedProductsData = await getRelatedProducts(id);
        setRelatedProducts(relatedProductsData.items);

        updateRecentlyViewed(productData.data);

        setLoading(false);
      } catch (error) {
        setError('Failed to load product data. Please try again later.');
        setLoading(false);
        handleApiError(error);
      }
    };

    fetchProductData();
  }, [id]);

  const updateRecentlyViewed = (currentProduct) => {
    try {
      const viewedString = Cookies.get('recentlyViewed');
      let viewed = viewedString ? JSON.parse(viewedString) : [];
  
      if (!Array.isArray(viewed)) {
        viewed = [];
      }
  
      const minimalProduct = {
        id: currentProduct.id,
        name: currentProduct.name,
        image_urls: currentProduct.image_urls,
        price: currentProduct.price,
        rating: currentProduct.rating,
        num_reviews: currentProduct.num_reviews,
        brand: currentProduct.brand
      };
  
      viewed = viewed.filter(item => item.id !== minimalProduct.id);
      viewed.unshift(minimalProduct);
      viewed = viewed.slice(0, 5);
  
      Cookies.set('recentlyViewed', JSON.stringify(viewed), { expires: 7 });
      setRecentlyViewed(viewed);
    } catch (error) {
      Cookies.remove('recentlyViewed');
      setRecentlyViewed([]);
    }
  };

  useEffect(() => {
    const savedTab = Cookies.get('activeTab');
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, []);

  const saveTabPreference = (tab) => {
    setActiveTab(tab);
    Cookies.set('activeTab', tab, { expires: 30 });
  };

  const increaseQuantity = () => setQuantity(prev => prev + 1);
  const decreaseQuantity = () => setQuantity(prev => (prev > 1 ? prev - 1 : 1));

  const handleAddToCart = async () => {
    try {
      await addToCart(product, quantity);
      setIsAddedToCart(true);
      toast.success('Product added to cart!');
      setTimeout(() => setIsAddedToCart(false), 2000);
    } catch (error) {
      handleApiError(error);
      toast.error('Failed to add product to cart. Please try again.');
    }
  };

  const handleToggleWishlist = async () => {
    try {
      if (isInWishlist(product.id)) {
        await removeFromWishlist(product.id);
        toast.success('Product removed from wishlist!');
      } else {
        await addToWishlist(product.id);
        toast.success('Product added to wishlist!');
      }
    } catch (error) {
      handleApiError(error);
      toast.error('Failed to update wishlist. Please try again.');
    }
  };

  const formatPrice = (price) => {
    return typeof price === 'number' ? price.toFixed(2) : parseFloat(price).toFixed(2);
  };

  const renderStars = (rating) => (
    <div className="flex">
      {[...Array(5)].map((_, i) => (
        <Star key={i} size={16} className={i < Math.floor(rating) ? "text-yellow-400 fill-current" : "text-gray-300"} />
      ))}
    </div>
  );
  const sortReviews = (reviews) => {
    switch (sortBy) {
      case 'highest':
        return [...reviews].sort((a, b) => b.rating - a.rating);
      case 'lowest':
        return [...reviews].sort((a, b) => a.rating - b.rating);
      case 'oldest':
        return [...reviews].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      case 'newest':
      default:
        return [...reviews].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
  };

  const filterReviews = (reviews) => {
    return reviews.filter(review => filterRating === 0 || review.rating >= filterRating);
  };

  const renderReviews = () => {
    if (!product.reviews || product.reviews.length === 0) {
      return (
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-lg p-8 text-center"
        >
          <h2 className="text-3xl font-bold mb-4 text-gray-800">Customer Reviews</h2>
          <Star size={48} className="text-yellow-400 mx-auto mb-4" />
          <p className="text-xl text-gray-700">No reviews yet. Be the first to review this product!</p>
          <button className="mt-6 bg-teal-500 text-white px-6 py-2 rounded-full hover:bg-teal-600 transition-colors duration-300">
            Write a Review
          </button>
        </motion.div>
      );
    }

    const sortedAndFilteredReviews = filterReviews(sortReviews(product.reviews));

    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-lg p-8"
      >
        <h2 className="text-3xl font-bold mb-6 text-gray-800">Customer Reviews</h2>
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 mb-4 md:mb-0">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="border rounded-full px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-teal-500"
            >
              <option value="newest">Newest First</option>
              <option value="oldest">Oldest First</option>
              <option value="highest">Highest Rated</option>
              <option value="lowest">Lowest Rated</option>
            </select>
            <select
              value={filterRating}
              onChange={(e) => setFilterRating(Number(e.target.value))}
              className="border rounded-full px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-teal-500"
            >
              <option value={0}>All Ratings</option>
              <option value={5}>5 Stars Only</option>
              <option value={4}>4 Stars & Up</option>
              <option value={3}>3 Stars & Up</option>
              <option value={2}>2 Stars & Up</option>
              <option value={1}>1 Star & Up</option>
            </select>
          </div>
          <p className="text-lg font-semibold text-gray-600">{sortedAndFilteredReviews.length} reviews</p>
        </div>
        <div className="space-y-8">
          {sortedAndFilteredReviews.map((review, index) => (
            <motion.div
              key={review.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="border-b pb-6"
            >
              <div className="flex items-start justify-between mb-4">
                <div className="flex items-center">
                  <div className="bg-gradient-to-r from-teal-400 to-blue-500 rounded-full p-2 mr-4">
                    <User size={24} className="text-white" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg text-gray-800">Anonymous User</h3>
                    <div className="flex items-center mt-1">
                      {renderStars(review.rating)}
                      <span className="ml-2 text-sm text-gray-600">
                        {new Date(review.created_at).toLocaleDateString('en-US', { 
                          year: 'numeric', 
                          month: 'long', 
                          day: 'numeric' 
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-gray-700 text-lg leading-relaxed">{review.comment}</p>
            </motion.div>
          ))}
        </div>
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8 text-center"
        >
        </motion.div>
      </motion.div>
    );
  };
  if (loading) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Loading...</h2>
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Error</h2>
          <p className="text-xl text-gray-600">{error}</p>
        </motion.div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Product not found</h2>
          <p className="text-xl text-gray-600">The product you're looking for doesn't exist or has been removed.</p>
          <Link to="/shop" className="mt-8 inline-block bg-teal-500 text-white px-6 py-3 rounded-full hover:bg-teal-600 transition-colors duration-300">
            Return to Shop
          </Link>
        </motion.div>
      </div>
    );
  }

  const productImages = product.images ? product.images.map(image => getStaticImageUrl(image, 'high')) : [getStaticImageUrl(product.image_urls, 'high')];

  return (
    <div className="container mx-auto px-4 py-8 md:py-16">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-xl shadow-2xl overflow-hidden"
      >
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-3/4 p-4 md:p-8">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2 mb-8 md:mb-0">
                <div className="relative mb-4 rounded-lg overflow-hidden">
                  <motion.img
                    key={activeImage}
                    src={productImages[activeImage]}
                    alt={product.name}
                    className="w-full h-[300px] md:h-[400px] object-cover"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  />
                  {productImages.length > 1 && (
                    <>
                      <button
                        onClick={() => setActiveImage((prev) => (prev > 0 ? prev - 1 : productImages.length - 1))}
                        className="absolute left-2 md:left-4 top-1/2 transform -translate-y-1/2 bg-white p-1 md:p-2 rounded-full shadow-md"
                      >
                        <ChevronLeft size={20} />
                      </button>
                      <button
                        onClick={() => setActiveImage((prev) => (prev < productImages.length - 1 ? prev + 1 : 0))}
                        className="absolute right-2 md:right-4 top-1/2 transform -translate-y-1/2 bg-white p-1 md:p-2 rounded-full shadow-md"
                      >
                        <ChevronRight size={20} />
                      </button>
                    </>
                  )}
                </div>
                {productImages.length > 1 && (
                  <div className="grid grid-cols-4 gap-2">
                    {productImages.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`${product.name} ${index + 1}`}
                        className={`w-full h-16 md:h-20 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                          activeImage === index ? 'ring-2 ring-teal-500' : 'opacity-70 hover:opacity-100'
                        }`}
                        onClick={() => setActiveImage(index)}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className="w-full md:w-1/2 md:pl-8">
                <h1 className="text-2xl md:text-3xl font-bold mb-4 text-gray-800">{product.name}</h1>
                <div className="flex items-center mb-4">
                  {renderStars(product.rating)}
                  <span className="ml-2 text-sm text-gray-600">({product.num_reviews} Reviews)</span>
                </div>
                <p className="text-xl md:text-2xl font-bold text-teal-600 mb-6">£{formatPrice(product.price)}</p>
                
                <div className="mb-6 bg-white p-4 rounded-lg shadow-sm">
                  <table className="w-full">
                    <tbody>
                      <tr className="border-b">
                        <td className="py-2 font-semibold text-gray-700">Brand</td>
                        <td className="py-2 text-gray-800">{product.brand?.name}</td>
                      </tr>
                      <tr className="border-b">
                        <td className="py-2 font-semibold text-gray-700">Category</td>
                        <td className="py-2 text-gray-800">{product.category?.name}</td>
                      </tr>
                      <tr>
                        <td className="py-2 font-semibold text-gray-700">Availability</td>
                        <td className="py-2 text-green-600 font-semibold">
                          {product.stock_quantity > 0 ? 'In stock' : 'Out of stock'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-8">
                  <div className="flex items-center border rounded-full overflow-hidden bg-white shadow-sm">
                    <button onClick={decreaseQuantity} className="px-3 py-2 hover:bg-gray-100 transition-colors duration-200">-</button>
                    <span className="px-3 py-2 border-l border-r">{quantity}</span>
                    <button onClick={increaseQuantity} className="px-3 py-2 hover:bg-gray-100 transition-colors duration-200">+</button>
                  </div>
                  <motion.button
                    className="w-full sm:w-auto flex-1 bg-teal-500 text-white py-2 px-4 rounded-full hover:bg-teal-600 transition-colors duration-300 flex items-center justify-center"
                    onClick={handleAddToCart}
                    whileTap={{ scale: 0.95 }}
                    disabled={product.stock_quantity === 0}
                  >
                    {isAddedToCart ? (
                      <Check size={18} className="mr-2" />
                    ) : (
                      <ShoppingCart size={18} className="mr-2" />
                    )}
                    {isAddedToCart ? 'Added to Cart' : 'Add to Cart'}
                  </motion.button>
                  <motion.button
                    className="bg-gray-200 text-gray-700 p-2 rounded-full hover:bg-gray-300 transition-colors duration-300"
                    whileTap={{ scale: 0.95 }}
                    onClick={handleToggleWishlist}
                  >
                    <Heart size={18} className={isInWishlist(product.id) ? "text-red-500 fill-current" : ""} />
                  </motion.button>
                </div>
                
                <div className="border-t pt-6">
                  <h3 className="font-semibold mb-4 text-gray-700">Share this product:</h3>
                  <div className="flex flex-wrap gap-4">
                    {['facebook', 'twitter', 'pinterest', 'linkedin', 'reddit'].map(platform => (
                      <motion.button
                        key={platform}
                        className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-300"
                        whileHover={{ y: -5 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Share2 size={16} className="text-gray-700" />
                      </motion.button>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8 md:mt-12">
              <div className="border-b mb-6 md:mb-8">
                <div className="flex flex-wrap gap-4 md:gap-8">
                  {['Description', 'Reviews', 'Shipping'].map((tab) => (
                    <button
                      key={tab}
                      className={`pb-2 md:pb-4 font-semibold text-base md:text-lg ${
                        activeTab === tab.toLowerCase() 
                          ? 'text-teal-500 border-b-2 border-teal-500' 
                          : 'text-gray-500 hover:text-gray-700'
                      }`}
                      onClick={() => saveTabPreference(tab.toLowerCase())}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
              </div>
              
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeTab}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {activeTab === 'description' && (
                    <div className="space-y-4 md:space-y-6">
                      <section>
                        <h2 className="text-xl md:text-2xl font-bold mb-3 md:mb-4 text-gray-800">What is {product.name}?</h2>
                        <div className="prose prose-sm md:prose-lg prose-blue mx-auto mt-4 md:mt-8">
                          <ReactMarkdown
                            components={{
                              h2: ({ node, ...props }) => (
                                <h2 className="text-xl md:text-2xl font-bold text-teal-600 mt-6 md:mt-8" {...props} />
                              ),
                              h3: ({ node, ...props }) => (
                                <h3 className="text-lg md:text-xl font-semibold text-teal-500 mt-4 md:mt-6" {...props} />
                              ),
                              ul: ({ node, ...props }) => (
                                <ul className="list-disc list-inside text-gray-700" {...props} />
                              ),
                              li: ({ node, ...props }) => (
                                <li className="mb-1 md:mb-2" {...props} />
                              ),
                              p: ({ node, ...props }) => (
                                <p className="text-gray-600 leading-relaxed mt-2 md:mt-4" {...props} />
                              ),
                            }}
                          >
                            {product.description}
                          </ReactMarkdown>
                        </div>
                      </section>
                      
                      <section>
                        <h3 className="text-lg md:text-xl font-bold mb-2 md:mb-3 text-gray-800">How {product.name} works?</h3>
                        <p className="text-gray-700 leading-relaxed">{product.short_description}</p>
                      </section>
                      
                      <section>
                        <h3 className="text-lg md:text-xl font-bold mb-2 md:mb-3 text-gray-800">How to use {product.name}?</h3>
                        <p className="text-gray-700 leading-relaxed">Follow the instructions provided by your healthcare provider or as indicated on the product label.</p>
                      </section>
                    </div>
                  )}
                  
                  {activeTab === 'reviews' && renderReviews()}

                  {activeTab === 'shipping' && (
                    <div>
                      <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-gray-800">Shipping Information</h2>
                      <p className="text-gray-700 mb-3 md:mb-4">We offer fast and reliable shipping options to ensure you receive your products as quickly as possible.</p>
                      <ul className="list-disc pl-5 space-y-1 md:space-y-2 text-gray-700">
                        <li>Standard Shipping: 3-5 business days</li>
                        <li>Express Shipping: 1-2 business days</li>
                        <li>Free shipping on orders over £50</li>
                      </ul>
                    </div>
                  )}
                </motion.div>
              </AnimatePresence>
            </div>
          </div>

          <div className="w-full lg:w-1/4 p-4 md:p-8 bg-gray-50">
            <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-gray-800">Related Products</h2>
            <div className="space-y-4 md:space-y-6">
              {relatedProducts.map((relatedProduct) => (
                <ProductCard key={relatedProduct.id} product={relatedProduct} viewMode="grid" />
              ))}
            </div>
          </div>
        </div>

        {recentlyViewed.length > 0 && (
          <div className="mt-8 md:mt-12 p-4 md:p-8 bg-gray-100 rounded-lg">
            <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-gray-800">Recently Viewed</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 md:gap-6">
              {recentlyViewed.map(product => (
                <ProductCard key={product.id} product={product} viewMode="grid" />
              ))}
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};
export default ProductDisplayPage;